@charset "utf-8";
/* CSS Document */

/* Global fixes for mobile view white space */
html,
body {
    overflow-x: hidden;
    /* Prevent horizontal scroll */
    width: 100%;
    margin: 0;
    padding: 0;
}

/* Fix for all sections */
section {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    padding-left: 0;
    padding-right: 0;
}

.container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}

/* Media queries for mobile views */
@media (max-width: 768px) {
    .row {
        margin-left: 0;
        margin-right: 0;
    }

    .col-md-6,
    .col-lg-6,
    .col-xl-6,
    .col-sm-12,
    .col-md-12,
    .col-md-4,
    .col-md-3,
    [class*="col-"] {
        padding-left: 10px;
        padding-right: 10px;
    }

    /* Fix specific sections */
    #top_banner,
    #about-us,
    #feature,
    #roadmap,
    #token_dist,
    #token_sale_06,
    #top_banner1,
    #whitepaper,
    #people {
        width: 100vw;
        max-width: 100%;
        overflow-x: hidden;
    }

    /* Fix for nested containers */
    .container .row .container {
        padding-left: 0;
        padding-right: 0;
    }

    /* Fix for images that might cause overflow */
    img {
        max-width: 100%;
        height: auto;
    }

    /* Fix for absolute positioned elements */
    .shape1,
    .shape2,
    .shape3,
    .shade_bg,
    .right_shine {
        display: none;
        /* Hide decorative elements on mobile that might cause overflow */
    }
}

/* Additional fixes for very small screens */
@media (max-width: 480px) {
    .container {
        padding-left: 10px;
        padding-right: 10px;
    }

    [class*="col-"] {
        padding-left: 5px;
        padding-right: 5px;
    }
}


body {
    background: #000804;
    color: #fff;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    line-height: 26px;
    overflow-x: hidden;
}

a,
a:hover,
a:focus,
input:focus,
select:focus,
textarea:focus,
button:focus {
    text-decoration: none;
    outline: none;
    color: inherit;
}

img {
    max-width: 100%;
}

ul,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: 500;
    position: relative;
    color: #fff;
}

h1 {
    font-size: 54px;
}

h2 {
    font-size: 38px;
}

h3 {
    font-size: 22px;
}

h4 {
    font-size: 22px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 18px;
}

p {
    margin: 0;
    padding: 0;
}

.dark_bg {
    /* background: rgb(223, 224, 241); */
    background: #000;
    /* background:linear-gradient(143deg, #f35524 7%, #c82538 25%, #1c1c1c 81%);
    background:-webkit-linear-gradient(143deg, #f35524 7%, #c82538 25%, #1c1c1c 81%); */
}

/* buttons */
.green_btn,
.stroke_btn {
    /* background-image:(linear, left top, right top, from(#3155cb), color-stop(51%, #516bbe), to(#4450b8));
    background-image:linear-gradient(to right, #3155cb 0%, #758fe6 51%, #7178b3 100%); */
    background: #faa423;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    display: inline-block;
    color: #100f0f;
    /* color: white; */
    font-weight: 600;
    border: none;
    font-size: 17px;
    padding: 9px 25px;
    transition: all ease 0.4s;
    border-radius: 10px;
    min-width: 180px;
    text-align: center;
    text-decoration: none;
}

.green_btn:hover,
.green_btn:focus {
    color: #f8f5f5;
    background: black;

}

.stroke_btn {
    /* background: transparent; */
    text-align: center;
    color: #f6ce7c;
    border: 2px solid #f7d868;
}


.stroke_btn {
    /* background: transparent; */
    border: 2px solid #f7d868;
    color: #fff;
    padding: 17px 20px;
}


/* input */
input,
select,
textarea {
    border-radius: 4px;
    padding: 10px 12px;
    font-size: 14px;
}

input[type="checkbox"],
input[type="radio"] {
    width: auto;
    vertical-align: sub;
}

label {
    color: #fff;
    font-size: 15px;
}

select option {
    background: #0b0c0e;
    color: #fff;
    font-size: 14px;
}

.form-control {
    color: #fff;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.3);
    height: 45px;
    font-size: 14px;
}

.form-control:focus {
    box-shadow: none;
    color: #ddd;
    background: rgba(0, 0, 0, 0.2);
    border-color: rgba(255, 255, 255, 0.4);
}


.heading {
    margin: 0 0 40px;
    font-size: 16px;
    font-weight: 400;
    color: #353f4a;
}

.heading h2 {
    margin: 0 0 10px;
}

/************ main css ***************/
.side_space {
    padding: 0 5rem;
}

/* navigation */
.navbar {
    padding: 20px 0;
}

.navbar .nav-item .nav-link.red_btn {
    color: #fff;
}

.dropdown-item {
    font-size: 14px;
}

.navbar-brand {
    height: auto;
    width: 200px;
    padding: 0;
}

.scrolled {
    background-color: rgba(0, 0, 0, 0.85);
    transition: background-color 200ms linear;
    box-shadow: 0 5px 8px rgb(0 0 0 / 10%);
    padding: 14px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    animation: smoothScroll 1s forwards;
}

@keyframes smoothScroll {
    0% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}

.side_nav li a {
    color: #fff;
    position: relative;
}

.inner_page .side_nav li a {
    color: #333;
}

.side_nav li a:hover,
.side_nav li a:focus {
    background: transparent;
}

.scrolled .side_nav li a {
    color: #333;
}

.inner_page {
    height: 65px;
    border-bottom: 1px solid #ebebeb;
}

.navbar .nav-item .nav-link {
    font-size: 15px;
    color: #d9dbda;
    font-weight: 500;
    padding: 7px 16px;
    transition: all ease 0.4s;
}

.navbar .nav-item .nav-link:hover {
    color: #ffbc43;
}

.navbar .nav-item .stroke_btn {
    color: #ffffff;
    border: 2px solid #faa423;
    min-width: auto;
    /* padding: 5px 30px; */
    font-size: 13px;
}


/* market section */
#market {
    margin: 6rem 0 0;
    background: rgb(0 0 0 / 60%);
    padding: 20px 0;
    position: relative;
    z-index: 2;
}

.market_bx .info h6 img {
    width: 25px;
    margin-right: 5px;
}

.green_clr {
    color: #1ba528;
}

.red_clr {
    color: #f83737;
}

.market_bx .info h6 {
    font-size: 16px;
    font-weight: 600;
    color: #fdd784;
    margin: 0 0 15px;
}

.heading {
    margin: 0 0 25px;
    color: #8b8b8b;
    font-size: 15px;
}

.market_bx .info h6 span {
    float: right;
    font-size: 14px;
    margin: 4px 0 0;
}

.market_bx .info p img {
    max-width: 110px;
    height: 100%;
}

.market_bx .info p {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.market_bx {
    padding: 0 30px;
}

#about-us {
    position: relative;
    z-index: -1;
    padding: 6rem 0;
    background: black;
}

.address_bx {
    background: #16181b;
    border-radius: 8px;
    height: 130px;
    width: 130px;
    margin: 0 auto;
    line-height: 120px;
    text-align: center;
    box-shadow: 0 10px 27px rgb(0 0 0 / 50%);
    min-width: 130px;
}

#about-us:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: rgb(10, 10, 10);
    /* background:linear-gradient(143deg, #f35524 7%, #c82538 25%, #1c1c1c 81%);
    background:-webkit-linear-gradient(143deg, #f35524 7%, #c82538 25%, #1c1c1c 81%); */
    bottom: 0;
    left: 0;
}

.address_bx img {
    max-width: 95px;
}

#feature {
    /* background:linear-gradient(143deg, #f35524 7%, #c82538 25%, #1c1c1c 81%);
    background:-webkit-linear-gradient(143deg, #f35524 7%, #c82538 25%, #1c1c1c 81%); */
    background: black;
    padding: 10rem 0;
    z-index: 0;
    position: relative;
}

#feature.feature_info,
.outer_bx,
.icon_bx,
.feature_bx p,
.feature_bx h3 {
    position: relative;
    z-index: 99999;
    /* Even higher z-index for the content */
}

.section-background {
    position: relative;
    z-index: 0;
}

#about-us h4 {
    color: #f1d97c;
    padding-left: 30px;
}

#about-us p {
    padding-right: 80px;
    color: rgb(211, 209, 209);
}

/* features aand address section start */
#address_link {
    position: relative;
}

.address_bx {
    background: #16181b;
    border-radius: 8px;
    height: 130px;
    width: 130px;
    margin: 0 auto;
    line-height: 120px;
    text-align: center;
    box-shadow: 0 10px 27px rgb(0 0 0 / 50%);
    min-width: 130px;
}

#address_link:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: -webkit-linear-gradient(30deg, #0b0c0e 20%, #008346, #fff8e9, #0b0c0e 80%);
    background: linear-gradient(30deg, #0b0c0e 20%, #008346, #fff8e9, #0b0c0e 80%);
    bottom: 0;
    left: 0;
}

.address_bx img {
    max-width: 95px;
}

#feature,
#address_link {
    background: black;
    padding: 6rem 0;
    z-index: 0;
    position: relative;
}

#address_link h4 {
    color: #f1d97c;
    padding-left: 30px;
}

#address_link p {
    padding-right: 60px;
    color: #000;
}

.feature_info {
    padding-left: 40px;
    color: rgb(218, 213, 213);
}

.feature_info h3 {
    color: #faa423;
    margin: 0 0 15px;
    position: relative;
    z-index: 99999;
}
#feature h1 {
    font-size: 3.5rem;
    font-weight: 700;
    color: #faa423;
    margin-top: 30px;
}
.gradient_bx img {
    max-width: 40px;
    color: #000;

}

.gradient_bx {
    /* background-image: (linear, left top, right top, from(#3155cb), color-stop(51%, #758fe6), to(#7178b3));
    background-image: linear-gradient(to right, #3155cb 0%, #3e5bb9 51%, #545ebd 100%); */
    background-color: #faa423;
    border-radius: 50%;
    height: 90px;
    width: 90px;
    line-height: 80px;
    color: #000;
}

.icon_bx {
    max-width: 134px;
    padding: 2px;
    border-radius: 50%;
    text-align: center;
}

.feature_bx {
    padding: 20px 20px;
    background: rgba(255, 255, 255, 0.1);
    /* Transparent background */
    border: 1px solid rgba(117, 117, 117, 0.3);
    /* Light border around box */
    border-radius: 20px;
    margin: 40px Auto;
    /* width: 80%; */
    position: relative;
    z-index: 99999;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for a lifted effect */
    backdrop-filter: blur(5px);
    isolation: isolate;
    min-height: 210px;
}

.feature_bx p {
    padding-top: 10px;
    color: rgb(206, 203, 203);
    z-index: 3;
    position: relative;
}

.shade_bg {
    position: absolute;
    top: 0;
    z-index: 1;
    right: 0;
    opacity: 0.4;
}

/* trusted platform section */
#token_sale_06 {
    text-align: center;
    padding: 100px 0 100px;
    position: relative;
    background: linear-gradient(to bottom,
            #e7dff3,
            /* Dark purple at the top */
            #322663,
            /* Medium purple in the middle */
            #616cd6,
            /* Light purple toward the bottom */
            #09080c
            /* Very light purple/lavender at the very bottom */
        );
    background: -webkit-linear-gradient(to bottom,
            #1c1c1c 10%,
            /* Light blue at the top */
            #8d7fd8 25%,
            /* Purple-ish shade in the middle */
            #cdd9e7 81%
            /* Dark shade towards the bottom */
        );
}

#token_sale_06 .shade_bg {
    opacity: 0.4;
    z-index: 0;
}

.token-distribution-sec {
    background: white;
    padding: 5rem 0;
}

.token-distribution-sec .heading {
    margin: 0 0 55px;
}

#token_sale_06 .pricing_items .single-wrapper .pricing_single {
    text-align: center;
    /* background:#079c58; */
    /* background-image:(linear, left top, right top, from(#cba031), color-stop(25%, #ffff79), to(#f7c648));
    background-image:linear-gradient(to right, #cba031 0%, #ffff79 25%, #f7c648 100%); */
    /* background-image:(linear, left top, right top, from(#3155cb), color-stop(25%, #758fe6), to(#7178b3));
    background-image:linear-gradient(to right, #3155cb 0%, #758fe6 25%, #7178b3 100%); */
    background: rgba(255, 255, 255, 0.2);
    margin: 25px 10px;
    position: relative;
    z-index: 1;
    border-radius: 20px;
}

#token_sale_06 .pricing_items .single-wrapper .pricing_single .offer_price h4 {
    margin-top: 22px;
    color: #ffffff;
    background: #222222;
    font-size: 16px;
    line-height: 40px;
    font-weight: 500;
    height: 40px;
    position: relative;
    text-align: center;
    float: right;
    padding: 0 30px 0 30px;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    width: 100%;
}

#token_sale_06 .pricing_items .single-wrapper .pricing_single .offer_details {
    padding: 60px 15px 15px;
}

#token_sale_06 .pricing_items .single-wrapper .pricing_single .offer_details span {
    font-size: 15px;
    font-weight: 500;
    color: white;
    opacity: 0.3;
}

#token_sale_06 .pricing_items .single-wrapper .pricing_single .offer_details h3 {
    font-size: 24px;
    font-weight: 600;
    margin-top: 25px;
    color: white;
    transition: all 0.3s ease-in-out;
}

#token_sale_06 .pricing_items .single-wrapper .pricing_single .offer_details img {
    padding: 30px 0;
}

#token_sale_06 .pricing_items .single-wrapper .pricing_single .offer_details p {
    color: white;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    line-height: 36px;
    transition: all 0.3s ease-in-out;
}

#token_sale_06 .pricing_items .single-wrapper .pricing_single .sale {
    border-top: 1px solid #0c6e41;
    transition: all 0.3s ease-in-out;
    /* background: #00543a; */
    /* background: #eeeb30; */
    border-radius: 0 0 16px 16px;
}

#token_sale_06 .pricing_items .single-wrapper .pricing_single .sale a {
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    line-height: 52px;
}

#token_sale_06 .pricing_bottom {
    margin-top: 70px;
}

#token_sale_06 .pricing_bottom .pricing_list_wrapper {
    padding: 0 45px;
}

#token_sale_06 .pricing_bottom .pricing_list_wrapper .item_list_1 li:before {
    border: 3px solid #f66f97;
}

#token_sale_06 .pricing_bottom .pricing_list_wrapper .item_list_1 li span {
    color: #f66f97;
}

#token_sale_06 .pricing_bottom .pricing_list_wrapper .item_list_2 li:before,
.Marketing_txt ul li:before {
    border: 3px solid #ffffff;
}

#token_sale_06 .pricing_bottom .pricing_list_wrapper .item_list_2 li span {
    color: #ffaf1e;
}

#token_sale_06 .pricing_bottom .pricing_list_wrapper .item_list_3 li:before {
    border: 3px solid #ffffff;
}

#token_sale_06 .pricing_bottom .pricing_list_wrapper .item_list_3 li span {
    color: #ffaf1e;
}

#token_sale_06 .pricing_bottom .pricing_list_wrapper ul {
    text-align: left;
    margin-left: 20px;
}

#token_sale_06 .pricing_bottom .pricing_list_wrapper ul:last-child {
    margin-right: 0px;
}

#token_sale_06 .pricing_bottom .pricing_list_wrapper ul li {
    position: relative;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}

#token_sale_06 .pricing_bottom .pricing_list_wrapper ul li:last-child {
    margin-bottom: 0;
    min-height: 0px;
}

#token_sale_06 .pricing_bottom .pricing_list_wrapper ul li:before,
.Marketing_txt ul li:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 11px;
    top: 5px;
    left: -30px;
    border-radius: 3px;
}

#token_sale_06 .pricing_bottom .pricing_list_wrapper ul li h3 {
    font-size: 15px;
    font-weight: 400;
    color: black;
    line-height: 25px;
    margin-bottom: 15px;
}

#token_sale_06 .pricing_bottom .pricing_list_wrapper ul li span {
    font-size: 18px;
    font-weight: 500;
}

#token_sale_06 .shape1 {
    position: absolute;
    top: -50%;
    left: 0;
    z-index: -1;
}

#token_sale_06 .shape2 {
    position: absolute;
    bottom: -12%;
    left: 0;
    z-index: -1;
}

#token_distribution_06 {
    padding: 150px 0;
    background: transparent;
}

#token_distribution_06 .distribution_single {
    text-align: center;
}

#token_distribution_06 .distribution_single .distribution_wrapper {
    max-width: 320px;
    position: relative;
    margin: 0px auto;
    z-index: 1;
}

#token_distribution_06 .distribution_single .distribution_wrapper .distribution_details {
    background: linear-gradient(109.8deg, rgba(250, 111, 152, 1) 5.6%, rgba(255, 189, 55, 1) 91.5%);
    border-radius: 50%;
    color: #122dff;
    display: inline-block;
    font-size: 20px;
    padding: 8px;
    text-decoration: none;
    position: relative;
}

#token_distribution_06 .distribution_single .distribution_wrapper .distribution_details h3 {
    font-size: 36px;
    font-weight: 600;
    color: #ffffff;
    padding-top: 42%;
    text-align: center;
    min-height: 304px;
    min-width: 304px;
    background: #181132;
    border-radius: 50%;
}

#token_distribution_06 .distribution_single .distribution_wrapper .distribution_details .token_shape .header-shape {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
}


@-webkit-keyframes zoomin1 {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes zoomin1 {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

#token_distribution_06 .distribution_single .distribution_wrapper .distribution_details .token_shape .shape1 img {
    -webkit-animation: zoomin1 10s ease-in infinite;
    animation: zoomin1 10s ease-in infinite;
    transition: all 5s ease-in-out;
}

@-webkit-keyframes zoomin2 {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.07);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes zoomin2 {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.07);
    }

    100% {
        transform: scale(1);
    }
}

#token_distribution_06 .distribution_single .distribution_wrapper .distribution_details .token_shape .shape2 img {
    -webkit-animation: zoomin2 5s ease-in infinite;
    animation: zoomin2 5s ease-in infinite;
    transition: all 5s ease-in-out;
}

@-webkit-keyframes zoomin3 {
    0% {
        transform: scale(0.9);
    }

    50% {
        transform: scale(1.06);
    }

    100% {
        transform: scale(0.9);
    }
}

@keyframes zoomin3 {
    0% {
        transform: scale(0.9);
    }

    50% {
        transform: scale(1.06);
    }

    100% {
        transform: scale(0.9);
    }
}

#token_distribution_06 .distribution_single .distribution_wrapper .distribution_details .token_shape .shape3 img {
    -webkit-animation: zoomin3 8s ease-in infinite;
    animation: zoomin3 8s ease-in infinite;
    transition: all 5s ease-in-out;
}

@-webkit-keyframes zoomin4 {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes zoomin4 {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

#token_distribution_06 .distribution_single .distribution_wrapper .distribution_details .token_shape .shape4 img {
    -webkit-animation: zoomin4 6s ease-in infinite;
    animation: zoomin4 6s ease-in infinite;
    transition: all 5s ease-in-out;
}

@-webkit-keyframes zoomin5 {
    0% {
        transform: scale(0.9);
    }

    50% {
        transform: scale(1.04);
    }

    100% {
        transform: scale(0.9);
    }
}

@keyframes zoomin5 {
    0% {
        transform: scale(0.9);
    }

    50% {
        transform: scale(1.04);
    }

    100% {
        transform: scale(0.9);
    }
}

#token_distribution_06 .distribution_single .distribution_wrapper .distribution_details .token_shape .shape5 img {
    -webkit-animation: zoomin5 7s ease-in infinite;
    animation: zoomin5 7s ease-in infinite;
    transition: all 5s ease-in-out;
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details {
    position: absolute;
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details p {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details span {
    font-size: 16px;
    font-weight: 400;
    position: relative;
    color: rgba(255, 255, 255, 0.3);
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details span:before {
    position: absolute;
    background: #ffffff;
    width: 1px;
    height: 35px;
    content: "";
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details_1 {
    bottom: 88%;
    left: 50%;
    transform: translate(-50%);
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details_1 p {
    margin-bottom: 60px;
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details_1 span:before {
    top: -41px;
    left: 50%;
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details_2 {
    bottom: 70%;
    right: 81%;
    width: 100%;
    text-align: right;
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details_2 p {
    margin-bottom: 30px;
    margin-right: 30px;
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details_2 span {
    float: right;
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details_2 span:before {
    top: -28px;
    left: -50%;
    transform: rotate(-45deg);
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details_3 {
    bottom: 70%;
    left: 81%;
    width: 70%;
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details_3 p {
    margin-bottom: 30px;
    margin-left: 30px;
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details_3 span {
    float: left;
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details_3 span:before {
    top: -28px;
    right: -50%;
    transform: rotate(45deg);
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details_4 {
    top: 70%;
    right: 81%;
    width: 100%;
    text-align: right;
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details_4 p {
    margin-top: 55px;
    margin-right: 30px;
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details_4 span {
    float: right;
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details_4 span:before {
    bottom: -28px;
    left: -50%;
    transform: rotate(45deg);
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details_5 {
    top: 88%;
    left: 50%;
    transform: translate(-50%);
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details_5 p {
    margin-top: 60px;
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details_5 span:before {
    bottom: -42px;
    left: 50%;
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details_6 {
    top: 70%;
    left: 81%;
    width: 100%;
    text-align: left;
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details_6 p {
    margin-top: 55px;
    margin-left: 30px;
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details_6 span {
    float: left;
}

#token_distribution_06 .distribution_single .distribution_wrapper .details_content .details_6 span:before {
    bottom: -28px;
    right: -50%;
    transform: rotate(-45deg);
}

/*==================================
  token distribution chart
====================================*/

.token-chart-item {
    margin-bottom: 40px;
}

.chart-label {
    padding-left: 20px;
}

.chart-label li {
    position: relative;
    margin-bottom: 45px;
    margin-bottom: 22px;
    /* color: rgb(155, 155, 155); */
    color: #656566;

}

.chart-label li span {
    font-weight: 700;
    color: #131212;
    margin-right: 10px;
}

.chart-label li img {
    position: absolute;
    left: -160px;
    top: 13px;
}

.chart-label li:nth-child(1) img {
    left: -169px;
    top: 15px;
    width: 80%;
}

.chart-label li:nth-child(2) img {
    left: -125px;
    top: 10px;
}

.chart-label li:nth-child(3) img {
    left: -113px;
    top: 9px;
    width: 50%;
}

.chart-label li:nth-child(4) img {
    left: -133px;
    top: -10px;
    width: 61%;
}

.chart-gap .xs-single-title {
    text-align: center;
    margin: 35px 0 0;
    /* color: #f7d868; */
    color: #4885e0;
    font-size: 26px;
}



/* Get Started  section */
#get_started {
    padding: 6rem 0;
}

.step_info h3 {
    margin: 0 0 15px;
    color: #f0d97c;
}

.step_info {
    position: relative;
    padding-left: 100px;
    padding-right: 15px;
    color: #d1d1d1;
    margin: 4rem 0 0;
}

.step_info span {
    position: absolute;
    font-size: 144px;
    font-weight: 700;
    left: 0;
    top: 49px;
    color: #1a1c1e;
    margin-left: 2px;
}

.steps:first-child .step_info span {
    margin-left: 25px;
}

.steps_img:after {
    content: '';
    position: absolute;
    border-bottom: 2px dashed #dfba6d;
    width: 73%;
    top: 40px;
}

.steps_img {
    position: relative;
}

.steps:last-child .steps_img:after {
    display: none;
}

.steps_img img {
    max-width: 115px;
}

/* whitepaper section*/
#whitepaper {
    padding: 6rem 0;
    position: relative;
    overflow: hidden;
    background: white;
}

#whitepaper .bg_bx {
    background: #121315;
    padding: 6rem 15px 6rem 12rem;
    position: relative;
    box-shadow: 0 10px 27px rgb(0 0 0 / 70%);
}

#whitepaper .bg_bx:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    background: linear-gradient(30deg, #fff8e9, #c89848, #fff8e9);
    top: 0;
    left: 0;
}

#whitepaper h2 {
    margin: 0 0 30px;
}

.whitepaper_info {
    padding-left: 40px;
}

.whitepaper_info p {
    color: #656566;
}

.whitepaper_img img {
    max-width: 250px;
    margin-left: 5rem;
    margin-top: -20px;
}

.left_shine {
    position: absolute;
    left: -52px;
    z-index: 0;
    max-width: 395px;
}

.right_shine {
    position: absolute;
    right: -52px;
    z-index: 1;
    max-width: 394px;
    transform: rotate(180deg);
    transform: -webkit-rotate(180deg);
}

#whitepaper .left_shine {
    bottom: 19rem;
}


/* news section */
/*==============================================
  blog section start
 ==============================================*/
#blog {
    padding: 5rem 0 6rem;
    background: #000e07;
    position: relative;
    overflow: hidden;
}

.blog-and-footer-area {
    background-image: radial-gradient(50% 50%, #070086 0%, #050069 37%, #02004b 87%);
}

.blog-and-social-area {
    position: relative;
}

.blog-and-social-area:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    content: '';
    /* background: url(../images/shapes/shapes1.png) no-repeat center center/cover; */
}

.blog-featured-post {
    position: relative;
}

.blog-featured-post .blog-feaured-img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 6px;
}

.blog-featured-post .blog-feaured-img:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: radial-gradient(50% 50%, #00d77d 0%, #05814d 37%, #042e1c 87%);
    opacity: 0.5;
}

.blog-featured-post .blog-feaured-img img {
    width: 100%;
    height: auto;
}

.blog-featured-post .blog-featured-content {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 40px 20px;
}

.blog-featured-post .blog-featured-content .post-meta-date {
    color: #c3d9ca;
    display: block;
    margin-bottom: 10px;
    font-weight: 400;
}

.blog-featured-post:hover .xs-blog-title {
    color: #1bd1ea;
}

.xs-blog-title {
    font-size: 24px;
    font-weight: 500;
    color: white;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.xs-blog-title:hover {
    color: #1bd1ea;
}

.xs-post-title {
    font-size: 15px;
    line-height: 23px;
    font-weight: 400;
}

.blog-post .single-blog-post {
    margin-bottom: 30px;
}

.blog-post .single-blog-post:hover .xs-post-title a {
    color: #1bd1ea;
}

.blog-post .single-blog-post .post-img {
    margin-right: 28px;
    position: relative;
    border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    overflow: hidden;
}

.blog-post .single-blog-post .post-img:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: rgb(0 60 36 / 40%);
}

.blog-post .single-blog-post .post-meta-date {
    display: block;
    color: #abb5ae;
    margin-bottom: 10px;
    font-size: 13px;
}

.blog-post .single-blog-post .xs-post-title a {
    color: #fff;
    text-decoration: none;
}

.blog-post .single-blog-post .xs-post-title a:hover {
    color: #1bd1ea;
}

.blog-post .single-blog-post:last-of-type {
    margin-bottom: 0;
}

.blog-featured-post .blog-feaured-img img {
    height: 350px;
    object-fit: cover;
}

.blog-post .single-blog-post .post-img img {
    width: 115px;
    height: 90px;
    object-fit: cover;
}

/* faq */

#faq-area-06 {
    padding: 6rem 0 9rem;
    position: relative;
    overflow: hidden;
    background: #000206;
}

#faq-area-06 .sub-title h2 {
    font-weight: 500;
    line-height: 55px;
    margin-bottom: 10px;
}

#faq-area-06 .faq-wrapper .accordion-wrapper .accordion-single {
    border: none;
    margin-bottom: 20px;
    border-radius: 50px;
}

#faq-area-06 .faq-wrapper .accordion-wrapper .accordion-single:last-child {
    margin-bottom: 0px;
}

#faq-area-06 .faq-wrapper .accordion-wrapper .accordion-single:checked .panel-heading {
    background: #333;
}

#faq-area-06 .faq-wrapper .accordion-wrapper .accordion-single .panel-heading {
    background: rgb(30 31 35);
    border: none;
    padding: 0px;
    height: auto;
    border-radius: 50px;
}

#faq-area-06 .faq-wrapper .accordion-wrapper .accordion-single .panel-heading a {
    padding: 0px 30px;
    line-height: 62px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 19px;
    font-weight: 500;
    display: inherit;
}

#faq-area-06 .faq-wrapper .accordion-wrapper .accordion-single .panel-heading a i {
    float: right;
}

#faq-area-06 .faq-wrapper .accordion-wrapper .accordion-single .accordion-content {
    padding: 15px 25px 25px;
}

#faq-area-06 .faq-wrapper .accordion-wrapper .accordion-single .accordion-content p {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 300;
    margin: 0;
}

#faq-area-06 .faq-wrapper .accordion-wrapper .panel-title>a:before {
    float: right !important;
    padding-right: 15px;
    font-family: "FontAwesome";
    content: "\f068";
    font-weight: 600;
    font-size: 14px;
    color: #7a839e;
    float: right;
}

#faq-area-06 .faq-wrapper .accordion-wrapper .panel-title>a.collapsed:before {
    float: right !important;
    font-family: "FontAwesome";
    content: "\f067";
    font-weight: 600;
    font-size: 14px;
    padding-right: 15px;
    color: #7a839e;
    float: right;
}

#faq-area-06 .faq-wrapper .accordion-wrapper .panel-title>a:hover,
#faq-area-06 .faq-wrapper .accordion-wrapper .panel-title>a:active,
#faq-area-06 .faq-wrapper .accordion-wrapper .panel-title>a:focus {
    text-decoration: none;
}


/* footer start */
footer {
    padding: 5rem 0;
    color: #cacaca;
    font-size: 14px;
    background: black;
}

.footer_link h3 {
    /* color: #f7d868; */
    color: #faa423;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 14px;
}
.footer_link .fa {
    color: white;
    
}

.footer_link li a {
    font-size: 14px;
    margin: 4px 0;
    display: block;
    text-decoration: none;
    color: #e7e2e2;
}

.footer_link p {
    font-size: 14px;
    margin: 4px 0;
    display: block;
    text-decoration: none;
    color: #e7e2e2;
}

.footer_logo {
    max-width: 235px;
    margin: 0 0 30px;
}

.cmpny_info {
    font-size: 15px;
    font-weight: 400;
}

.bottom_social a {
    width: 35px;
    height: 35px;
    display: inline-block;
    border: 1px solid #83858b;
    border-radius: 50%;
    line-height: 35px;
    margin: 0 1px;
    color: rgb(209, 204, 204);
}


/* login, sign up and forgot password pages */

.fade_bg {
    background: #f5f4fb;
}

#form_page {
    padding: 6rem 0;
}

.form_data img {
    margin: 0 0 40px;
}

#form_page .form_bg {
    background: #11151b;
    padding: 35px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    box-shadow: 0 0 5px rgb(0 0 0 / 5%);
}

#form_page .form-control {
    background: #171c23;
    border: 1px solid #404a56;
    font-size: 13px;
    height: 50px;
    color: #fff;
    border-radius: 50px;
    padding: 10px 21px;
    font-weight: 300;
}

#form_page .check label {
    position: relative;
    top: 1px;
    font-weight: 400;
    left: 5px;
    font-size: 13px;
    background: #12171d;
    border: none;
    line-height: 0;
}

#form_page .red_btn {
    width: 100%;
}

#form_page .form-group {
    position: relative;
}

#form_page .form-group.btn_part {
    margin-bottom: 12px;
}

#form_page h5 {
    margin: 0.5rem 0 3rem;
    font-size: 24px;
}

#form_page label {
    position: absolute;
    top: -3px;
    font-weight: 500;
    left: 18px;
    font-size: 13px;
    background: #12171d;
    border: 4px solid #12171d;
    line-height: 0;
}

#form_page .form-group {
    margin-bottom: 25px;
}

.form_link p {
    display: inline-block;
}

.form_link {
    margin: 30px 0 0;
}

.form_link a {
    color: #108bbe;
    margin: 0 10px;
}

.form_link {
    font-size: 14px;
    font-weight: 400;
}

.center_part {
    border-top: 1px solid #292f37;
    position: relative;
    padding: 0 0 50px;
    margin: 50px 0 0;
}

.center_part span {
    width: 30px;
    height: 30px;
    background: #27323f;
    display: inline-block;
    border-radius: 50%;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    line-height: 30px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -15px;
    bottom: 35px;
}

.form_social a {
    width: 45px;
    height: 45px;
    background: #0f6ea4;
    display: inline-block;
    line-height: 45px;
    font-size: 20px;
    border-radius: 50%;
    margin: 0 3px;
    color: #fff;
}

.form_social a img {
    vertical-align: sub;
    max-width: 24px;
}

#form_page .white_bx {
    padding: 30px 30px 40px;
}

footer.inner_footer .copyright_part {
    margin: 0;
    border: none;
}

footer.inner_footer {
    padding-top: 0;
}

#form_page .regis_check label {
    position: relative;
    top: auto;
    left: auto;
}

#form_page .regis_check input {
    vertical-align: middle;
}


/* about */
#our_platform {
    padding: 7rem 0;
    position: relative;
    overflow: hidden;
}

#our_platform .heading_part {
    margin: 0 0 80px;
}

#inner_part {
    margin: 7rem 0 3rem;
    color: #ddd;
    position: relative;
    overflow: hidden;
}

#inner_banner .shade_bg {
    opacity: 1;
}

#inner_part h3 {
    font-size: 34px;
    color: #00a9e1;
}

#inner_banner {
    background: rgb(8 37 114 / 50%);
    padding: 12rem 0 10rem;
    overflow: hidden;
    position: relative;
}

.about_feature .feature_info {
    padding: 0;
    margin: 40px 0 0;
}

.about_feature .icon_bx {
    margin: 0 auto;
}

.box_info h4 {
    color: #00a9e1;
    margin: 0 0 12px;
    font-size: 24px;
}

.box_info .bg_bx:before {
    content: '';
    width: 0;
    height: 64%;
    border: 0.5px dashed rgba(255, 255, 255, 0.4);
    position: absolute;
    left: -32px;
    top: 56px;
}

.box_info .bg_bx:after {
    content: '';
    width: 0;
    height: 13px;
    border: 0.5px dashed rgba(255, 255, 255, 0.4);
    position: absolute;
    left: -32px;
    top: 0;
}

.box_info .bg_bx {
    border-radius: 10px;
    color: #ddd;
    font-size: 14px;
    margin: 0 10px 40px 32px;
    position: relative;
    background: rgb(0 2 6);
    padding: 30px;
}

.box_info span {
    width: 60px;
    height: 60px;
    background: #ab46f9;
    display: inline-block;
    border-radius: 50%;
    color: #000;
    line-height: 60px;
    font-size: 30px;
    font-weight: 700;
    margin-top: -25px;
    margin-bottom: 20px;
}

span.numbering {
    background: transparent;
    position: absolute;
    left: 2px;
    top: 30px;
    color: #00a9e1;
    font-size: 24px;
}

.graph_bg {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
    opacity: 0.6;
}

.note {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.6);
    padding: 30px 15px 0;
}

#our_platform .shade_bg {
    z-index: 0;
}

#stats {
    padding: 3rem 0 3rem;
    position: relative;
    background: #000206;
}

.stat_info h6 {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 18px;
    margin: 26px 0 6px;
}

.stat_info span {
    font-size: 32px;
    font-weight: 600;
    color: #fff;
    display: inline-block;
    margin: 6px 0 0;
}

.stat_icon img {
    max-width: 50px;
}

.stat_info .box_bg {
    padding: 20px 10px;
    color: #fff;
    border-radius: 20px;
    position: relative;
}

.stat_info .box_bg:after {
    position: absolute;
    left: -17px;
    content: '';
    width: 15px;
    height: 15px;
    background: #00a9e1;
    top: 50%;
    transform: rotate(45deg);
}

.stat_info:first-child .box_bg:after {
    display: none;
}

#our_platform .heading {
    margin: 0 0 70px;
}

/* community page */
#box_layout {
    padding: 5rem 0;
    position: relative;
}

#box_layout .outer_bx {
    background: rgb(0 2 6 / 60%);
    padding: 30px;
    border-radius: 10px;
    font-size: 14px;
    line-height: 25px;
    margin: 25px 0;
}

#box_layout .outer_bx .feature_info {
    padding: 0;
}

#box_layout .outer_bx .icon_bx {
    margin: 0 0 35px;
}


/*************** responsive part *****************/
@media(max-width:1399px) {
    #trusted_plat .bg_bx {
        padding: 5rem 5rem 3rem 15px;
    }

    #whitepaper .bg_bx {
        padding: 5rem 15px 3rem 4rem;
    }
}

@media(max-width:1199px) {
    .side_space {
        padding: 0 30px;
    }

    .navbar .nav-item .nav-link {
        font-size: 14px;
        padding: 7px 14px;
    }

    .navbar-brand {
        width: 165px;
    }

    .banner_txt h1 {
        font-size: 54px;
    }

    .banner_txt {
        font-size: 18px;
    }

    .address_bx {
        height: 110px;
        width: 110px;
        line-height: 110px;
        min-width: 110px;
    }

    .address_bx img {
        max-width: 80px;
    }

    #address_link h4 {
        padding-left: 19px;
        font-size: 20px;
    }

    #trusted_plat .bg_bx {
        padding: 5rem 2rem 3rem 15px;
    }

    .trusted_img img {
        max-width: 450px;
        margin-left: -13rem;
        margin-top: 109px;
    }

    #trusted_plat .box img {
        top: -33rem;
        left: 10.2rem;
        max-width: 100px;
    }

    #trusted_plat .light img {
        max-width: 50px;
    }

    #trusted_plat .shade2.light {
        left: -11rem;
        bottom: 18%;
    }

    #trusted_plat .light {
        bottom: 23%;
        left: 10rem;
    }

    .step_info {
        padding-left: 80px;
        padding-right: 0;
    }

    .step_info span {
        font-size: 110px;
        top: 30px;
    }

    .step_info h3 {
        font-size: 22px;
    }

    #top_banner .shape4 {
        height: 400px;
        width: 465px;
    }

    #top_banner .box img {
        max-width: 220px;
        margin-top: 100px;
        margin-left: 36px;
    }
}

@media(max-width:991px) {
    h2 {
        font-size: 32px;
    }

    h3 {
        font-size: 20px;
    }

    h4 {
        font-size: 18px;
    }

    body {
        font-size: 15px;
        line-height: 24px;
    }

    #trusted_plat h2 {
        font-size: 32px;
    }

    .whitepaper_img img {
        max-width: 265px;
        margin-left: 0rem;
        margin-top: 50px;
    }

    .trusted_img img {
        max-width: 330px;
        margin-left: -8rem;
        margin-top: 152px;
    }

    #address_link .outer_bx {
        display: block !important;
        text-align: center;
    }

    #address_link h4 {
        padding-left: 0;
        font-size: 20px;
        margin-top: 20px;
    }

    .navbar .nav-item .nav-link {
        font-size: 13px;
        padding: 3px 7px;
    }

    .navbar-brand {
        width: 110px;
    }

    .market_bx {
        padding: 0 15px;
    }

    .market_bx .info p img {
        max-width: 75px;
    }

    .market_bx .info h6 span {
        float: none;
        font-size: 14px;
        margin: 12px 0 0;
        display: block;
    }

    .market_bx .info strong {
        font-size: 15px;
    }

    .market_bx .info h6 {
        margin: 0;
    }

    /* .banner_txt h1 {
    
} */

    .icon_bx {
        max-width: 100px;
        height: 100px;
    }

    .gradient_bx img {
        max-width: 50px;
        color: #000;
    }

    .feature_info {
        padding-left: 20px;
    }

    .feature_info h3 {
        margin: 0 0 10px;
        z-index: 3;
        position: relative;
    }

    #trusted_plat .box img {
        top: -29rem;
        left: 7.4rem;
        max-width: 75px;
    }

    #trusted_plat .light img {
        max-width: 40px;
    }

    #trusted_plat .shade2.light {
        left: -6.5rem;
        bottom: 29%;
    }

    #trusted_plat .light {
        bottom: 33%;
        left: 9rem;
    }

    .trusted_info {
        font-size: 15px;
    }

    .step_info {
        padding-left: 60px;
        margin: 2rem 0 0;
    }

    .step_info h3 {
        font-size: 20px;
    }

    .steps:first-child .step_info span {
        margin-left: 0;
    }

    .step_info span {
        font-size: 80px;
        top: 20px;
    }

    .steps_img img {
        max-width: 90px;
    }

    .steps_img:after {
        top: 30px;
    }

    .whitepaper_info {
        padding-left: 0;
        font-size: 15px;
    }

    #whitepaper .bg_bx {
        padding: 5rem 15px 5rem 4rem;
    }

    .timeline .left::before {
        top: 33px;
        width: 60px;
        right: 20px;
        background-size: 60px;
    }

    .timeline .right::before {
        top: 25px;
        width: 60px;
        left: 20px;
        background-size: 60px;
    }

    .timeline .content {
        padding: 9px 55px;
    }

    #top_banner .shape4 {
        height: 292px;
        width: 350px;
    }

    #top_banner .box img {
        max-width: 170px;
        margin-top: 68px;
        margin-left: 15px;
    }
}

@media(max-width:767px) {
    .steps_img:after {
        display: none;
    }

    .navbar-toggler-icon {
        color: #fff;
    }

    .navbar-collapse {
        background: rgba(0, 0, 0, 0.9);
        text-align: center;
        padding: 15px 0;
    }

    .navbar .nav-item .nav-link {
        font-size: 15px;
        padding: 6px 7px;
    }

    .navbar .nav-item .stroke_btn {
        min-width: 150px;
    }

    .side_space {
        padding: 0 15px;
    }

    .navbar-brand {
        width: 150px;
        padding: 0 15px;
    }

    .banner_txt {
        margin: 1rem 0 0;

    }

    .market_bx {
        padding: 20px 15px;
    }

    #market {
        margin: 7rem 0 0;
        padding: 15px 0;
    }

    #address_link {
        text-align: center;
    }

    .heading {
        font-size: 16px;
    }

    .trusted_img {
        text-align: center;
    }

    .trusted_img img {
        max-width: 330px;
        margin: 0 auto 40px;
    }

    #trusted_plat .box img {
        top: -21rem;
        left: -16px;
        max-width: 73px;
        right: 0;
        margin: 0 auto;
    }

    #trusted_plat .bg_bx {
        padding: 8rem 2rem 4rem 15px;
    }

    #trusted_plat .light {
        bottom: 30%;
        left: 8rem;
    }

    #trusted_plat .shade2.light {
        right: -219px;
        bottom: 35%;
        left: 0;
    }

    #trusted_plat .light {
        bottom: 30%;
        left: 0;
        right: 275px;
    }

    .step_info {
        padding-left: 0;
        margin: 2rem 0 0;
        text-align: center;
    }

    .step_info span {
        display: none;
    }

    .steps_img {
        position: relative;
        margin: 40px 0 0;
    }

    #get_started .heading {
        margin-bottom: 0 !important;
    }

    #whitepaper {
        padding: 0 0 7rem;
        position: relative;
    }

    .whitepaper_img {
        text-align: center;
    }

    .timeline_info {
        padding: 10px 0;
    }

    .timeline .content {
        padding: 9px 60px;
    }

    .timeline_info.left .content {
        padding-left: 0;
    }

    .timeline_info.right .content {
        padding-right: 0;
    }

    .news_bg {
        margin: 0 0 30px;
    }

    #news {
        padding: 0 0 4rem;
    }

    .footer_link {
        margin: 0 0 20px;
    }

    footer {
        padding: 4rem 0 3rem;
    }

    .timeline .right::before {
        top: 22px;
        left: -8px;
    }

    .timeline .left::before {
        top: 30px;
        right: -7px;
    }

    /* #roadmap:before{width:100%;} */
    .banner_img {
        text-align: center;
    }

    #top_banner .box img {
        max-width: 200px;
    }

    .banner_img {
        display: none;
    }
}

@media(max-width:575px) {
    h2 {
        font-size: 24px;
    }

    .banner_txt h1 {
        font-size: 40px;
    }

    #feature,
    #address_link {
        padding: 4rem 1rem;
        z-index: 0;
        position: relative;
    }

    .trusted_img img {
        max-width: 250px;
    }

    #trusted_plat .box img {
        top: -16rem;
        left: -13px;
        max-width: 50px;
    }

    #trusted_plat h2 {
        font-size: 24px;
        margin: 0 0 15px;
    }

    .whitepaper_img img {
        max-width: 220px;
    }

    #whitepaper .bg_bx {
        padding: 5rem 40px;
    }

    #trusted_plat .light {
        bottom: 32%;
        right: 206px;
    }

    #trusted_plat .shade2.light {
        right: -163px;
        bottom: 38%;
    }

    #address_link .outer_bx {
        margin: 0 0 30px;
    }

    .left_shine,
    .right_shine {
        max-width: 290px;
    }

    .list ul li {
        display: block;
        justify-content: left;
    }

    .list ul li span {
        display: block;
        padding: 4px 0;
        text-align: left;
        word-break: break-word;
    }

    #private_sale .bg_bx {
        padding: 2rem 15px;
    }
}

.token-footertext {
    visibility: visible;
    animation-duration: 30s;
    animation-delay: 10s;
    animation-name: fadeInUp;
}

a.text-center {
    margin-right: 5px;
}

.countdown_time {
    margin: 50px 0px;
}

/*  people section */
#people {
    padding: 3rem 0 5rem;
}

#people .user_info img {
    max-width: 120px;
    height: 150px;
    margin: 0 0 20px;
    border-radius: 50%;
}

#people .user_info {
    margin: 0 0 50px;
    padding: 30px;
}

#people h4 {
    color: white;
}

#people a {
    text-decoration: none;
}

#people .user_info small {
    color: #bfbfbf;
    display: block;
    margin: 0 0 6px;
}

.contentbox {
    height: auto;
    width: auto;
    border: 1px solid black;
    background-color: #004022;
    padding: 20px;
    text-align: left;
    align-items: start;
}

.fa.fa-twitter::before {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    content: "";
    background-color: currentColor;
    -webkit-mask: url('data:image/svg+xml;utf8,<svg viewBox="0 -8 26 30" xmlns="http://www.w3.org/2000/svg"><g><path fill="white" d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></g></svg>') no-repeat center;
    mask: url('data:image/svg+xml;utf8,<svg viewBox="0 -8 26 30" xmlns="http://www.w3.org/2000/svg"><g><path fill="white" d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></g></svg>') no-repeat center;
    background-position: center 3px;
}




/* banner section start */

#top_banner {
    margin-top: 4rem;
    position: relative;
    font-size: 18px;
    /* background: linear-gradient(
        to bottom,
        #09080c,   
        #322663,  
        #616cd6,   
        #e7dff3   
    );
    background: -webkit-linear-gradient(
        to bottom,
        #1c1c1c 10%,   
        #8d7fd8 25%,  
        #cdd9e7 81%  
    ); */
    background-color: black;
    background-size: cover;
    color: white;
}

#top_banner::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 300px;
    /* Adjust height as needed */
    background: url('../images/chart.png') no-repeat center bottom;
    background-size: cover;
    z-index: 1;
}

#top_banner1 {
    position: relative;
    font-size: 18px;
    background: linear-gradient(to bottom,
            #09080c,
            /* Dark purple at the top */
            #322663,
            /* Medium purple in the middle */
            #616cd6,
            /* Light purple toward the bottom */
            #e7dff3
            /* Very light purple/lavender at the very bottom */
        );
    background: -webkit-linear-gradient(to bottom,
            #1c1c1c 10%,
            /* Light blue at the top */
            #8d7fd8 25%,
            /* Purple-ish shade in the middle */
            #cdd9e7 81%
            /* Dark shade towards the bottom */
        );
    background-size: cover;
    color: white;
}

#top_banner h5 {
    margin-top: 50px;
    display: inline-block;
    padding: 8px 15px;
    background-color: rgba(255, 255, 255, 0.1);
    /* semi-transparent white */
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.3);
    /* light border */
    border-radius: 8px;
    /* rounded corners */
    font-size: 0.9em;
    font-weight: bold;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    /* subtle shadow */
}

#top_banner h1 {
    font-size: 3.3rem;
    font-weight: 700;
    text-align: center;
    color: white;
}

#top_banner h1 span {
    font-size: 3.5rem;
    font-weight: 700;
    color: #faa423;
}

#top_banner p {
    text-align: center;
    color: #e7e2e2;
}

@media (max-width: 480px) {
    #top_banner h1 {
        font-size: 3rem;
        text-align: center;
        font-weight: 700;

    }

}


#disclaimer {
    padding: 5rem 0;
    background: black;
    text-align: center;

} 
#disclaimer h1 {
    font-size: 3.5rem;
    font-weight: 700;
    color: #faa423;
    margin-top: 30px;
}

#disclaimer p {
    color: #e7e2e2;
    padding: 20px 22%;

}


#why {
    padding: 5rem 0;
    background: black;
    text-align: center;

} 
#why h1 {
    font-size: 3.5rem;
    font-weight: 700;
    color: #faa423;
    margin-top: 30px;
}

#why p {
    color: white;
    padding: 20px 10%;

}
#buy h1 {
    font-size: 4rem;
    font-weight: 700;
    color: #faa423;
    margin-top: 30px;
    text-align: center;

}
#buy {
    padding: 5rem 20px;
    background: black;

}

#stage {
    padding: 5rem 20px;
    background: black;
    text-align: center;

} 
#stage h1 {
    font-size: 4rem;
    font-weight: 700;
    color: #faa423;
    margin-top: 30px;
}
#stage .line {
    border: 2px solid #faa423;
    width: 100%; 
}
#stage .dash-line {
    border: 2px dashed #f0eeec;
    width: 100%;
    /* margin-top: 80px;  */
}
#stage h2 {
    color: white; 
}
#stage h4 {
    color: rgb(201, 197, 197); 
}
#stage p{
    color: rgb(218, 216, 216);
    font-size: 1.5rem; 
}
#stage h5 {
    color: #faa423;
}
#stage input {
    border: 1px solid #faa423;
    background: #000;
    color: white;
    height: 60px;
    font-size: 1.5rem;
}
#stage .col {
    color: white;
    padding: 25px 10px;
    border: 1px solid #1b1b1b;
    border-radius: 24px;
    min-height: 130px;
    margin: 10px;
    align-items: center;
    text-align: center;
    box-shadow: none;
    background: #1b1b1b;
    
}

#stage .buy-btn {
    text-align: center;
    align-items: center;
    padding: 12px 50px;
    background-color: #faa423;
    color: black;
    border: none;
    border-radius: 10px;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
}

#stage .buy-btn:hover {
    background-color: #faa423;
}
#faq {
    padding: 5rem 0;
    background: black;
    text-align: center;

} 
#faq h1 {
    font-size: 3.5rem;
    font-weight: 700;
    color: #faa423;
    margin-top: 30px;
}
#faq h4 {
    color: #faa423;
    margin-top: 10px;
}
#faq .faq-text {
    /* display: flex; */
    justify-content: space-between;
    margin-top: 40px;
    text-align: start;
    border: 1px solid rgb(44, 43, 43);
    background: rgb(24, 23, 23);
    padding: 15px;
}

#faq p {
    color: white;
}

#laptop {
    padding: 5rem 0;
    background: black;
    text-align: center;
    background-size: cover;
    position: relative;

}
#laptop::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 600px;
    /* Adjust height as needed */
    background: url('../images/elip.png');
    background-size: auto;
    z-index: 1;
}
#rewards {
    padding: 5rem 0;
    background: black;
    text-align: center;
    background-size: cover;
    position: relative;
}
#rewards::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 600px;
    /* Adjust height as needed */
    background: url('../images/elip.png');
    background-size: auto;
    z-index: 1;
}

#rewards h1 {
    font-size: 3.5rem;
    font-weight: 700;
    color: #faa423;
    margin-top: 30px;
}

#rewards h5 {
    margin-top: 7px;
    display: inline-block;
    color: white;
    padding: 8px 12px;
    border: 1px solid white;
    border-radius: 10px;
    align-items: center;
    text-align: center;
    background: rgb(70, 60, 60);
}

#rewards p {
    color: white;
    padding: 20px 0;

}

#rewards .rewardbox {
    color: white;
    padding: 100px 0;
    border: 1px solid white;
    border-radius: 24px;
    min-height: 300px;
    margin: 10px;
    align-items: center;
    text-align: center;
    box-shadow: none;
    border: none;
}

.bg-stake {
    background: linear-gradient(90deg,
            #070707 0%,
            #1f1e1e 50%,
            #674070 100%);

}

.bg-lightyellow {
    background: linear-gradient(135deg,
            /* You can change the angle as needed (e.g., 45deg, 180deg) */
            #dba8cf 0%,
            /* Light orange color start */
            #472743 100%
            /* Deeper orange color end */
        );
}

#tokeninfo {
    padding: 5rem 20px;
    background: black;
    
}

#tokeninfo h1 {

    font-weight: 700;
    color: white;
    margin-top: 30px;
}

#tokeninfo h4 {
    color: white;

}

#tokeninfo h4 span {
    margin-right: 20px;
    color: white;
    height: auto;
    width: auto;
    border-radius: 50%;
    border: 2px solid #faa423;
    padding: 10px 20px;
    background: #3a3131;
}

#tokeninfo h5 {
    color: white;
    padding-top: 25px;

}

#tokeninfo p {
    color: white;
    padding: 20px 0;

}

#tokeninfo .buy-btn {
    text-align: center;
    align-items: center;
    padding: 15px 20%;
    background-color: #faa423;
    color: black;
    border: none;
    border-radius: 10px;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    margin-top: 5px;
}

#tokeninfo .buy-btn:hover {
    background-color: #faa423;
}

#tokenomic {
    padding: 5rem 0px;
    align-items: center;
    text-align: center;
    background: black;
}

#tokenomic h1 {
    font-size: 3.5rem;
    font-weight: 700;
    color: #faa423;
    margin-top: 30px;
}

#tokenomic h2 {
    font-size: 3rem;
    font-weight: 500;
    color: white;
    margin-top: 30px;
}

#tokenomic h2 span {
    font-size: 2.7rem;
    font-weight: 500;
    color: #faa423;
    margin-top: 30px;
}

#tokenomic p {
    text-align: center;
    color: #e7e2e2;
    margin-bottom: 80px;
}
#tokenomic img {
    width: 100%;
}

@media (max-width: 480px) {
    #tokenomic h1 {
        font-size: 3rem;
        text-align: center;
        font-weight: 700;

    }

}

.shape1,
.shape2,
.shape3,
.counter_shape2,
.counter_shape1 {
    position: absolute;
    z-index: 0;
}

.shape1 {
    bottom: 0;
}

.shape2 {
    top: 0;
    left: 7rem;
}

.shape3 {
    top: 0;
    left: 9rem;
}

.counter_shape1 {
    bottom: -152px;
    right: 0;
    z-index: 0;
}

.counter_shape2 {
    top: 0;
    left: 0;
}

.progress-bar {
    background: #ff9f00;
}

.banner_txt {
    position: relative;
    z-index: 2;
}

.banner_txt h1 {
    margin: 60px 0 30px;
    position: relative;
}

.banner_txt h2 {
    margin-top: 50px;
    font-size: 2.5rem;
}

.banner_txt h1 span,
.heading span,
.info_text h2 span {
    font-weight: 600;
}

.banner_img img {
    max-width: 95%;
}

.banner_img {
    margin: 80px 0 0;
}

.banner_txt {
    margin: 2rem 0 0;
    line-height: 29px;
    font-weight: 400;

    font-size: 20px;
    padding-bottom: 7rem;
}

.banner_txt p {

    color: rgb(241, 241, 241);
    font-size: 18px;
}

.counter_bg {
    background-image: (linear, left top, right top, from(#3155cb), color-stop(51%, #758fe6), to(#7178b3));
    background-image: linear-gradient(to right, #3155cb 0%, #101a3b 51%, #545ebd 100%);
    padding: 2.5rem 3rem 8rem;
    border-radius: 0 0 15rem 15rem;
    margin-bottom: -5rem;
}

#countdown ul li {
    display: inline-block;
    margin: 0 15px;
    color: #d1d4dd;
    font-size: 15px;
}

#countdown ul li span {
    width: 75px;
    height: 75px;
    background: #212f5d;
    display: block;
    line-height: 80px;
    border-radius: 50%;
    font-size: 28px;
    font-weight: 600;
    color: #fff;
    margin: 0 0 5px;
}

.ico_counter h2 {
    color: #fff;
    margin: 0 0 50px;
}

.progress {
    border-radius: 50px;
    height: 10px;
}

.ico_progress p {
    display: flex;
    justify-content: space-between;
    color: #fafafa;
    font-size: 15px;
    margin: 2px 0;
}

#top_banner .countdown_time .count-time_btn {
    margin-bottom: -57px;
}

#top_banner .countdown_time .count-time_btn a {
    background: white;
    padding: 13px 50px;
    color: #000000;
    line-height: 54px;
    border-radius: 50px;
    text-decoration: none;
}

#top_banner .countdown_time .count-time_btn a span {
    font-weight: 600;
    font-size: 17px;
}

.whitepaper_img img {
    max-width: 26rem;
    margin: 40px 0;
}

@media (max-width: 768px) {
    .whitepaper_img img {
        max-width: 18rem;
        margin: 40px 0;
    }
}

.info_text h1 {
    margin: 30px 0;
}

.info_text p {
    margin: 10px 0;

}

/* Container styling */
.crypto-card {
    margin: 10% auto;
    /* Centers the card and maintains top margin */
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 12px;
    padding: 30px 20px;
    width: 70%;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    color: #fff;
}

/* Mobile styles */
@media (max-width: 768px) {
    .crypto-card {
        width: 60%;
        margin: 10% 120px;
        /* Top margin maintained, horizontal margin added */
        padding: 20px 15px;
        /* Slightly reduced padding for mobile */
    }
}

/* Extra small devices */
@media (max-width: 480px) {
    .crypto-card {
        margin: 10% 10px;
        /* Slightly reduced horizontal margin for very small devices */
        width: auto;
    }
}

.crypto-card h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5em;
}

.crypto-card p {
    text-align: center;
    margin-bottom: 20px;
    color: #b0b3b8;
}

.crypto-card label {
    display: block;
    margin: 10px 0 5px;
    font-size: 0.9em;
    color: #b0b3b8;
}

.crypto-card input,
.crypto-card select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    background: rgba(255, 255, 255, 0.2);
    border: none;
    border-radius: 6px;
    color: #fff;
    font-size: 1em;
}

.crypto-card input::placeholder {
    color: #ccc;
}

.crypto-card .terms {
    text-align: center;
    font-size: 0.8em;
    margin-bottom: 20px;
    color: #b0b3b8;
}

.crypto-card .terms a {
    color: #b0b3b8;
    text-decoration: none;
}

.banner_txt .buy-btn {
    text-align: center;
    align-items: center;
    padding: 12px 50px;
    background-color: #faa423;
    color: black;
    border: none;
    border-radius: 10px;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 100px;
}

.banner_txt .buy-btn:hover {
    background-color: #faa423;
}


/* roadmap */
#roadmap {
    padding: 5rem 0;
    overflow: hidden;
    /* background-image:(linear, left top, right top, from(#3155cb), color-stop(51%, #758fe6), to(#7178b3));
    background-image:linear-gradient(to right, #3155cb 0%, #101a3b 51%, #545ebd 100%);} */
    background: linear-gradient(to bottom,
            #e7dff3,
            /* Dark purple at the top */
            #322663,
            /* Medium purple in the middle */
            #171a35,
            /* Light purple toward the bottom */
            #09080c
            /* Very light purple/lavender at the very bottom */
        );
    background: -webkit-linear-gradient(to bottom,
            #1c1c1c 10%,
            /* Light blue at the top */
            #8d7fd8 25%,
            /* Purple-ish shade in the middle */
            #cdd9e7 81%
            /* Dark shade towards the bottom */
        );
}

#roadmap #myTab {
    background: #1a2958;
    display: inline-block;
    border-radius: 100px;
    padding: 0 30px;
    border: none;
}

#roadmap .nav-item {
    color: #c5c5c5;
    display: inline-block;
}

#roadmap .nav-item .nav-link {
    border-radius: 0;
    font-weight: 500;
    border: none;
    padding: 15px 25px;
}

#roadmap .nav-item .nav-link.active {
    background: #ff8500;
    color: #fff;
    border: none;

}

#roadmap .nav-item .nav-link:hover {
    color: #fff;
    border: none;
}

#roadmap .roadmap_info h6 {
    color: #fff;
    font-weight: 500;
    margin: 40px 0 15px;
}

.roadmap_info p {
    padding-right: 40px;
    color: #cfced2;
}

#roadmap .roadmap_info span {
    position: absolute;
    z-index: 9;
    top: -15px;
    left: 34px;
}

#roadmap .roadmap_info span b {
    -webkit-animation: loader-6-1 2s infinite;
    animation: loader-6-1 2s infinite;
    position: absolute;
    top: -20px;
    left: -19px;
    right: 0;
    margin: 0 auto;
    width: 39px;
    height: 39px;
    background: #5e6b95;
    border-radius: 50%;
    z-index: -1;
}

#roadmap .roadmap_info span:before {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 15px;
    width: 15px;
    background: #6071ad;
    content: "";
    border-radius: 50%;
    transform: translate(-50%, -50%);
}

#roadmap .roadmap_info span:after {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 40px;
    width: 40px;
    content: "";
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background: #5e6b95;
    z-index: -2;
    opacity: 0.5;
}

@-webkit-keyframes loader-6-1 {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(1.8);
        opacity: 0;
    }
}

@keyframes loader-6-1 {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(0);
        transform: translate3d(0, 0, 0) scale(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(1.8);
        transform: translate3d(0, 0, 0) scale(1.8);
        opacity: 0;
    }
}

#roadmap .tab-content {
    margin: 2rem 0 0;
    position: relative;
}

#roadmap .tab-content .tab-pane {
    position: relative;
    z-index: 1;
}

.roadmap_info ul li {
    color: #cfced2;
}

#roadmap .tab-content::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-top: 2px dashed #5e688d;
    width: 100%;
}

#roadmap .nav-item .nav-link {
    padding: 9px 10px;
    font-size: 14px;
}

#roadmap .tab-content {
    margin: 3rem 0 0;
}

#roadmap #myTab {
    padding: 0 7px;
}

#roadmap .heading {
    margin: 0 0 15px;
}

#roadmap .tab-content::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 35px;
    border-left: 2px dashed #5e688d;
    border-top: none;
}

#roadmap .tab-content {
    padding-left: 5rem;
}

#roadmap .roadmap_info span {
    position: absolute;
    z-index: 9;
    top: 12px;
    left: -30px;
}

#roadmap .roadmap_info h6 {
    margin: 0 0 11px;
}

.roadmap_bx {
    margin-bottom: 35px;
}

.tab-content>.tab-pane {
    position: relative;
    z-index: 9;
}

#roadmap .tab-pane {
    padding: 0 !important;
}

#roadmap {
    padding: 4rem 0 3rem;
}

.fixed-top {
    /* background-image:(linear, left top, right top, from(#3155cb), color-stop(51%, #758fe6), to(#7178b3));
    background-image:linear-gradient(to right, #3155cb 0%, #101a3b 51%, #545ebd 100%); */
    background: black;
}
.fixed-top li{
    font-size: 2rem;
}




/* token distribution */
#token_dist {
    padding: 5rem 0;
    background: linear-gradient(to bottom,
            #09080c,
            /* Dark purple at the top */
            #635697,
            /* Medium purple in the middle */
            #171a35,
            /* Light purple toward the bottom */
            #e7dff3
            /* Very light purple/lavender at the very bottom */
        );
    background: -webkit-linear-gradient(to bottom,
            #1c1c1c 10%,
            /* Light blue at the top */
            #8d7fd8 25%,
            /* Purple-ish shade in the middle */
            #cdd9e7 81%
            /* Dark shade towards the bottom */
        );
}

.token_points ul li {
    padding: 10px 30px;
    color: rgb(236, 234, 234);
    border: 1px solid rgba(0, 0, 0, 0.07);
    box-shadow: 0 0 23px -3px rgb(0 0 0 / 8%);
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.2);
    margin: 20px 0;
    position: relative;
    font-size: 17px;
}

.token_points ul li:before {
    content: '';
    width: 13px;
    height: 13px;
    color: #4885e0;
    background: rgb(236, 234, 234);
    left: -5px;
    position: absolute;
    border-radius: 50%;
    top: 28px;
}

.token_points ul li span {
    color: #000000;
    font-size: 28px;
    font-weight: 700;
    display: inline-block;
    vertical-align: sub;
    margin-right: 12px;
}

#token_dist .img_part img {
    max-width: 30rem;
}

@media (max-width: 768px) {
    #token_dist .img_part img {
        max-width: 18rem;
        margin: 30px 0;
    }
}

#token_dist p {
    color: rgb(236, 234, 234);
}